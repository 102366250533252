import React, { FC, useState, useEffect } from "react";

type FactProps = {
    showValue: number;
};

const FactPage: FC<FactProps> = ({ showValue }: FactProps) => {
    console.log("value is", showValue);
    const showFact = showValue % 3 === 0;
    const [fact, setFact] = useState(null);

    useEffect(() => {
        if (!showFact) {
            return;
        }
        fetch("https://api.api-ninjas.com/v1/facts?limit=1", {
            method: "GET",
            headers: {
                "X-API-Key": process.env.REACT_APP_API_KEY || ''
            }
        }).then(response => response.json())
            .then((data) => setFact(data[0].fact))
    }, [showFact]);

    if (fact) {
        console.log("testing output", fact)
    }
    return (
        <div className="content">
            {showFact && fact && <div>{fact}</div>}
            {showFact && !fact && <div>We're getting a fact for you!!!!!!!</div>}
            {!showFact && <div>Sorry nothing to say this time</div>}
        </div>
    );
}

export default FactPage;