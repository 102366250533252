import React from 'react';
import './App.css';
import FactPage from './FactPage';

function App() {
  const value = Math.floor(Math.random() * 10);
  return (
    <div className="App">
      <header className="App-header">
        <FactPage showValue={value} />
      </header>
    </div>
  );
}

export default App;
